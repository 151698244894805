
import React, { useState } from "react";


const ECommerceFAQ = () => {
  // Define the state to handle which FAQ is open
  const [openIndex, setOpenIndex] = useState(null);

  // Sample FAQ data
  const faqData = [
    { question: "Which is the best platform for our e-commerce startup?", answer: "The best platform depends on your business needs, budget, and future scalability plans. We work with a variety of platforms, including Shopify, WooCommerce, Magento, and custom-built solutions. After analyzing your goals, we’ll recommend the platform that aligns best with your startup's vision." },
    { question: "How much does an e-commerce website cost?", answer: "The cost of an e-commerce website varies depending on your specific needs, such as the number of products, design complexity, features, and integrations required. At Trice Technologies, we offer tailored solutions that fit your budget and requirements. Contact us for a detailed quote." },
    { question: "How long does it take to build an e-commerce website?", answer: "The timeline for building an e-commerce website depends on factors like project scope, design preferences, and the complexity of functionalities. A basic website might take a few weeks, while a more advanced, feature-rich site could take several months. We'll provide a project timeline after understanding your requirements." },
    { question: "I want to redesign my existing e-commerce website. Can you help?", answer: "Absolutely! We specialize in redesigning e-commerce websites to improve user experience, functionality, and overall design. Whether you want a fresh look or need to enhance site performance, we can transform your current site into a modern, efficient e-commerce platform." },
    { question: "Can you integrate third-party tools and payment gateways into my e-commerce website?", answer: "Absolutely! We can integrate a variety of third-party tools such as CRM systems, inventory management, shipping APIs, and multiple payment gateways like PayPal, Stripe, Razorpay, or any platform of your choice to streamline your business operations. " },
    { question: "Will my e-commerce website be mobile-friendly?", answer: "Yes, all e-commerce websites developed by Trice Technologies are fully responsive and optimized for mobile devices. With a growing number of users shopping via smartphones, we ensure your site provides a seamless experience across all screen sizes." }
   
];

  // Toggle the open state for each FAQ item
  const toggleFAQ = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // Close if the same index is clicked again
    } else {
      setOpenIndex(index); // Open the clicked FAQ
    }
  };

  return (
    <div className="faq-container my-5">
      <h3>FAQs</h3>
      <div className="faq-list mt-2 ">
        {faqData.map((item, index) => (
          <div  
            key={index}
            className={`faq-item ${openIndex === index ? "open" : ""} mb-4` }
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq-question">
              <span>{item.question}</span>
              <span className="faq-icon" style={{color: 'orange'}}>{openIndex === index ? "-" : "+"}</span>
            </div>
            <div className="faq-answer">{item.answer}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ECommerceFAQ;
