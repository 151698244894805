import React, { useState } from "react";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    message: "",
  });
  const [status, setStatus] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, contact, message } = formData;

    if (!name || !contact || !message) {
      setStatus("Please fill out all fields.");
      return;
    }

    fetch("https://tricetechnologies.in/contact-form-handler.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
       
        if (data.status === "success") {
          setFormData({ name: "", contact: "", message: "" });
          setStatus(data.message);
        }
      })
      .catch((error) => {
        setStatus("Failed to send message. Please try again later.");
      });
  };

  return (
    <div>
      <Helmet>
        <title>Contact Trice Technologies | Get in Touch Today</title>
        <meta
          name="title"
          content="Contact Trice Technologies | Get in Touch Today"
        />
        <meta
          name="description"
          content="Get a FREE consultation with Trice Technologies! Discuss your project needs and explore how our expertise can benefit you. Contact us today!"
        />
        <meta
          name="keywords"
          content="web design Palakkad, website design in Kerala, Palakkad web development company, web designing Palakkad, website design company in Palakkad, web development company in Palakkad, domain registration Palakkad, web Hosting company Palakkad"
        />
        <link
          rel="canonical"
          href="https://tricetechnologies.in/Contact-Trice-Technologies-Get-in-Touch-Today"
        />
        <meta
          property="og:title"
          content="Contact Trice Technologies | Get in Touch Today"
        />
        <meta property="og:type" content="Website" />
        <meta
          property="og:url"
          content="https://tricetechnologies.in/Contact-Trice-Technologies-Get-in-Touch-Today"
        />
        <meta
          property="og:image"
          content="https://tricetechnologies.in/Logo-Dark.png"
        />
        <meta
          property="og:description"
          content="Get a FREE consultation with Trice Technologies! Discuss your project needs and explore how our expertise can benefit you. Contact us today!"
        />
      </Helmet>
      <section className="section section-intro context-dark">
        <div
          className="intro-bg"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg1.webp)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        ></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 text-center">
              <h1 className="font-weight-bold wow" data-aos="fade-left">
                Contact Us
              </h1>
              <p className="intro-description wow" data-aos="fade-right">
                We are always happy to help you!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="section-header">
          <div className="container contact">
            <h2>Contact Us</h2>
            <p>
              Your next big idea could start with a simple hello.
              <br />
              Contact us today and let’s explore how we can work together to
              achieve your goals.
            </p>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="contact-info">
              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <i className="fa fa-home"></i>
                </div>

                <div className="contact-info-content">
                  <h4>Address</h4>
                 
                  <p>
                    2nd Floor, Prime Complex,
                    <br />
                    Kalmandapam,
                    <br />
                    Palakkad
                    <br />
                    678013
                  </p>
                </div>
              </div>

              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <i className="fa fa-phone"></i>
                </div>

                <div className="contact-info-content">
  <h4>Phone</h4>
  <p>
    <a href="tel:+919142121210" style={{color: '#000'}}>+91 9142121210</a>
  </p>
</div>
              </div>

              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <i className="fa fa-envelope"></i>
                </div>

                <div className="contact-info-content">
                  <h4>Email</h4>
                  <p>info@tricetechnologies.in</p>
                </div>
              </div>
            </div>

            <div className="contact-form">
              <form id="contact-form" onSubmit={handleSubmit}>
                <h2>Send Your Message</h2>
                {/* Status message */}
    {status && (
      <div
        className={`status-message ${
          status.includes("error") ? "error" : "Thank you for contacting us"
        }`}
      >
        {status}
      </div>
    )}
                <div className="input-box">
                  <input
                    type="text"
                    required
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Full Name"
                  />
                  
                </div>

                <div className="input-box">
                  <input
                    type="text"
                    required
                    name="contact"
                    value={formData.contact}
                    onChange={handleInputChange}
                    placeholder="Phone no/Email"
                  />
                 
                </div>

                <div className="input-box">
                  <textarea
                    required
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    placeholder="Type your Message..."
                  />
                 
                </div>

                <div className="input-box">
                  <input type="submit" value="Send" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <iframe
        style={{ width: "100%", height: "100vh" }}
        loading="lazy"
        src="https://maps.google.com/maps?q=trice%20technologies%2C%20palakkad&#038;t=m&#038;z=15&#038;output=embed&#038;iwloc=near"
        title="trice technologies, palakkad"
        aria-label="trice technologies, palakkad"
      ></iframe>
    </div>
  );
};

export default Contact;
