import React, { useState, useEffect, useRef } from 'react';

function AutoCounter() {
  const [count, setCount] = useState(0);
  const targetNumber = 15;
  const counterRef = useRef(null);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const interval = setInterval(() => {
            setCount(prevCount => {
              if (prevCount >= targetNumber) {
                clearInterval(interval);
                return prevCount; // Stop updating when target is reached
              }
              return prevCount + 1;
            });
          }, 100);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1 // Adjust as needed
    });

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  const reset = () => {
    setCount(0);
  };

  return (
    <section className="section section-sm bg-default" ref={counterRef}>
      <div className="container">
        <div className="row row-30 row-xl-24 justify-content-center align-items-center align-items-lg-start text-left">
          <div className="col-md-6 col-lg-5 col-xl-4 text-center">
            <a className="text-img" href="#">
              <div id="particles-js"></div>
              <span className="counter">{count}</span>
            </a>
          </div>
          <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4">
            <div className="text-width-extra-small offset-top-lg-24 wow fadeInUp">
              <h3 className="title-decoration-lines-left">Years of Experience</h3>
              <p className="text-gray-500">We Design Brands & Businesses to make them an online sensation.</p>
              <a className="button button-secondary button-pipaluk" href="/Contact-Trice-Technologies-Get-in-Touch-Today">Get in touch</a>
            </div>
          </div>
          <div class="col-sm-10 col-md-8 col-lg-6 col-xl-4 wow fadeInRight" data-wow-delay=".1s">
              <div class="row justify-content-center border-2-column offset-top-xl-26">
                <div class="col-9 col-sm-6">
                  <div class="counter-amy">
                    <div class="counter-amy-number"><span class="counter">300</span><span class="symbol">+</span>
                    </div>
                    <h6 class="counter-amy-title">Websites</h6>
                  </div>
                </div>
                <div class="col-9 col-sm-6">
                  <div class="counter-amy">
                    <div class="counter-amy-number"><span class="counter">100+</span>
                    </div>
                    <h6 class="counter-amy-title">Customers</h6>
                  </div>
                </div>
                <div class="col-9 col-sm-6">
                  <div class="counter-amy">
                    <div class="counter-amy-number"><span class="counter">50+</span>
                    </div>
                    <h6 class="counter-amy-title">Product Shoots</h6>
                  </div>
                </div>
                <div class="col-9 col-sm-6">
                  <div class="counter-amy">
                    <div class="counter-amy-number"><span class="counter">10+</span>
                    </div>
                    <h6 class="counter-amy-title">Employees</h6>
                  </div>
                </div>
              </div>
            </div>
         
        </div>
      </div>
      <div className="col-lg-6 col-xl-12 align-self-center client-banner" style={{backgroundColor: '#9b9b9b33',paddingTop: '2%'}}>
  <div className="container row row-30 justify-content-center clients" style={{margin: '0 auto'}}>
    <div className="col-12 col-sm-6 col-md-4 col-lg-3 wow mb-2" data-aos='zoom-in'>
      <img src="images/01-Logo-Idtr.webp" alt="idtr" width="270" height="117" loading='lazy'/>
    </div>
    <div className="col-12 col-sm-6 col-md-4 col-lg-3 wow mb-2" data-aos='zoom-in'>
      <img src="images/02-Logo-Adorn.webp" alt="adorn" width="270" height="117" loading='lazy'/>
    </div>
    <div className="col-12 col-sm-6 col-md-4 col-lg-3 wow mb-2" data-aos='zoom-in'>
      <img src="images/03-Logo-AyurKerala.webp" alt="ayurkerala" width="270" height="117" loading='lazy'/>
    </div>
    <div className="col-12 col-sm-6 col-md-4 col-lg-3 wow mb-5" data-aos='zoom-in'>
      <img src="images/04-Logo-Karimpuzha.webp" alt="karimpzha" width="270" height="117" loading='lazy'/>
    </div>
    <div className="col-12 col-sm-6 col-md-4 col-lg-3 wow mb-5" data-aos='zoom-in'>
      <img src="images/06-Logo-Oush.webp" alt="Oush" width="270" height="117" loading='lazy'/>
    </div>
    <div className="col-12 col-sm-6 col-md-4 col-lg-3 wow mb-2" data-aos='zoom-in'>
      <img src="images/05-Logo-Tulsi.webp" alt="Tulsi" width="270" height="117" loading='lazy'/>
    </div>
    <div className="col-12 col-sm-6 col-md-4 col-lg-3 wow mb-2" data-aos='zoom-in'>
      <img src="images/07-Logo-Legacy.webp" alt="Legacy" width="270" height="117" loading='lazy'/>
    </div>
    <div className="col-12 col-sm-6 col-md-4 col-lg-3 wow mb-2" data-aos='zoom-in'>
      <img src="images/08-Logo-Fedora.webp" alt="Fedora" width="270" height="117" loading='lazy'/>
    </div>
  </div>
</div>

    </section>
  );
}

export default AutoCounter;
