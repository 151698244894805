import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink,Navigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Home from './Home';
import About from './About';
import Services from './Services';
import Contact from './Contact';
import Dm_blog from './Dm-blog';
import Ecommerce from './Ecommerce-blog';
import E_seo from './Seo-blog';
import Content from './Services/Content-Creation';
import DigitalMarketing from './Services/Digital-Marketing';
import PhotoVideo from './Services/Photo-Video';
import SEO from './Services/SEO';
import WebDesigning from './Services/Web-Designing';
import Blogs from './Blogs';
import ECommerce from './Services/Ecommerce';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import DmBlog from './DmBlog';
import Website from './Website';
import Professional_SEO from './Professional-SEO';
import Sales from './Products/Sales';
import EduManage from './Products/EduManage';


import ScrollToTop from './ScrollTop';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    AOS.init({ 
      duration: 1000,
      easing: 'ease-in-out',
      delay: 200,
      once: false
    });
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Function to close menu when a navigation link is clicked
  const handleNavLinkClick = () => {
    setMenuOpen(false);
  };

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <nav>
          <div className="wrapper">
            <div className="logo">
              <NavLink to="/">
                <img src={`${process.env.PUBLIC_URL}/images/web/Logo-Dark.webp`} alt="Logo" loading='lazy' />
              </NavLink>
            </div>
            <input type="checkbox" id="menu-btn" checked={menuOpen} onChange={toggleMenu} />
            <input type="checkbox" id="close-btn" checked={!menuOpen} onChange={toggleMenu} />
            <ul className={`nav-links ${menuOpen ? 'show' : ''}`}>
              <label htmlFor="close-btn" className="btn close-btn">
                <span><img src={`${process.env.PUBLIC_URL}/images/close.webp`} alt='close-btn' width="25" height="25" /></span>
              </label>
              <li><NavLink to="/" end onClick={handleNavLinkClick} style={({ isActive }) => ({ color: isActive ? 'red' : 'black' })}>Home</NavLink></li>
              <li><NavLink to="/WebDesign-and-Digital-Marketing-Experts" onClick={handleNavLinkClick} style={({ isActive }) => ({ color: isActive ? 'red' : 'black' })}>About</NavLink></li>
              <li>
                <NavLink to="/services" className="desktop-item" onClick={handleNavLinkClick} style={({ isActive }) => ({ color: isActive ? 'red' : 'black' })}>Services</NavLink>
                <input type="checkbox" id="showDrop" />
                <label htmlFor="showDrop" className="mobile-item">Services</label>
                <ul className="drop-menu">
                  <li><NavLink to="/webdesigning-company-in-palakkad-kerala/" onClick={handleNavLinkClick} style={({ isActive }) => ({ color: isActive ? 'red' : 'black' })}>Web Development</NavLink></li>
                  <li><NavLink to="/best-digital-marketing-strategist-in-palakkad" onClick={handleNavLinkClick} style={({ isActive }) => ({ color: isActive ? 'red' : 'black' })}>Digital Marketing</NavLink></li>
                  <li><NavLink to="/seo-company-in-palakkad-kerala " onClick={handleNavLinkClick} style={({ isActive }) => ({ color: isActive ? 'red' : 'black' })}>SEO</NavLink></li>
                  <li><NavLink to="/Professional-Photography-and-Videography-Services" onClick={handleNavLinkClick} style={({ isActive }) => ({ color: isActive ? 'red' : 'black' })}>Photography & Videography</NavLink></li>
                  <li><NavLink to="/Best-Content-Creation-Services-in-palakkad" onClick={handleNavLinkClick} style={({ isActive }) => ({ color: isActive ? 'red' : 'black' })}>Content Creation</NavLink></li>
                  <li><NavLink to="/ecommerce-website-development-company-in-kerala" onClick={handleNavLinkClick} style={({ isActive }) => ({ color: isActive ? 'red' : 'black' })}>Ecommerce Development</NavLink></li> 
                </ul>
              </li>

              <li>
                <NavLink to="#" className="desktop-item">Products</NavLink>
                <input type="checkbox" id="showDrop" />
                <label htmlFor="showDrop" className="mobile-item">Products</label>
                <ul className="drop-menu">
                  <li><NavLink to="/sales-tracking-management-app" onClick={handleNavLinkClick} style={({ isActive }) => ({ color: isActive ? 'red' : 'black' })}>Sale App</NavLink></li>
                  {/* <li><NavLink to="/edumanage" onClick={handleNavLinkClick} style={({ isActive }) => ({ color: isActive ? 'red' : 'black' })}>EduManage</NavLink></li> */}
                 
                </ul>
              </li>
              <li><NavLink to="/Trice-Technologies-Blog-Latest-Tech-Trends" onClick={handleNavLinkClick} style={({ isActive }) => ({ color: isActive ? 'red' : 'black' })}>Blogs</NavLink></li>
              <li><NavLink to="/Contact-Trice-Technologies-Get-in-Touch-Today" onClick={handleNavLinkClick} style={({ isActive }) => ({ color: isActive ? 'red' : 'black' })}>Contact</NavLink></li>
            </ul>
            <label htmlFor="menu-btn" className="btn menu-btn">
              <span><img src={`${process.env.PUBLIC_URL}/images/menu.webp`} alt='menu' width="25" height="25" /></span>
            </label>
            <div className="social-media-icons">
      
      <a href="https://www.linkedin.com/company/trice-technologies/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><span><img src={`${process.env.PUBLIC_URL}/images/linkdin.webp`} alt='Linkedin' width='100%' height='100%'/></span><span class="sr-only">LinkedIn</span></a>
      <a href="https://www.facebook.com/tricetechnologiesindia/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><span><img src={`${process.env.PUBLIC_URL}/images/fb.webp`} alt='Facebook' width='100%' height='100%' /></span><span class="sr-only">LinkedIn</span></a>
      <a href="https://www.instagram.com/trice_technologies/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><span><img src={`${process.env.PUBLIC_URL}/images/insta.webp`} alt='Instagram' width='100%' height='100%'/></span><span class="sr-only">LinkedIn</span></a>
    </div>
          </div>
        </nav>

        <Routes>
        <Route path='/' element={<Home/>} ></Route>
          <Route path='/WebDesign-and-Digital-Marketing-Experts' element={<About/>} ></Route>
          <Route path='/services' element={<Services/>} ></Route>
        
          <Route path='/Trice-Technologies-Blog-Latest-Tech-Trends' element={<Blogs/>} ></Route>
          <Route path='/Contact-Trice-Technologies-Get-in-Touch-Today' element={<Contact/>} ></Route>
          <Route path='/digital-marketing-blog' element={<Dm_blog/>} ></Route>
          <Route path='/ecommerce-blog' element={<Ecommerce/>} ></Route>
          <Route path='/ecommerce-seo' element={<E_seo/>} ></Route>
          <Route path='/Best-Content-Creation-Services-in-palakkad' element={<Content/>} ></Route>
          <Route path='/best-digital-marketing-strategist-in-palakkad' element={<DigitalMarketing/>} ></Route>
          <Route path='/Professional-Photography-and-Videography-Services' element={<PhotoVideo/>} ></Route>
          <Route path='/seo-company-in-palakkad-kerala' element={<SEO/>}></Route>
          <Route path='/webdesigning-company-in-palakkad-kerala/' element={<WebDesigning/>}></Route>
          <Route path='/Digital_Marketing_Strategist' element={<DmBlog/>}></Route>
          <Route path='/cheapest-web-design-company' element={<Website/>}/>
          <Route path='/professional-seo-services' element={<Professional_SEO/>}/>
          <Route path='/ecommerce-website-development-company-in-kerala' element={<ECommerce/>}/>
          <Route path='/sales-tracking-management-app' element={<Sales/>}/>
          {/* <Route path='/edumanage' element={<EduManage/>}></Route> */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>

      <FloatingWhatsApp
        phoneNumber="+919142121210"
        accountName="Trice Technologies"
        logo={`${process.env.PUBLIC_URL}/images/web/Logo-Dark.webp`} // Check this line
        allowClickAway
        message="Hello, how can I help you?"
      />
{}
      {/* Footer */}
      <footer className="section footer-classic section-sm">
        <div className="container">
          <div className="row row-30">
            <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInLeft">
              <a className="brand" href="index.html">
                <img className="brand-logo-dark" src="images/web/logo.png" alt="logo" width="100" height="17" loading='lazy'/>
              </a>
              <p className="footer-classic-description offset-top-0 offset-right-25">We make eye-catching, user-friendly, and responsive designs, giving your clients equal access from mobiles, laptops, or desktops. </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 wow" data-wow-delay=".3s">
              <p className="footer-classic-title">Quick Links</p>
              <ul className="footer-classic-nav-list">
                <li><a href="/">Home</a></li>
                <li><a href="/WebDesign-and-Digital-Marketing-Experts">About us</a></li>
                <li><a href="/services ">Services</a></li>
                <li><a href="/Contact-Trice-Technologies-Get-in-Touch-Today">Contacts</a></li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp address">
              <div className="d-block offset-top-0">TRICE TECHNOLOGIES <br></br><span style={{ paddingLeft: '2%' }}>2nd floor, Prime Complex,</span><div className="d-lg-block">Kalmandapam, Palakkad, Kerala 678013.</div></div>
              <div style={{ marginTop: '2%' }}>
                <a className="d-inline-block accent-link" href="mailto:#">info@tricetechnologies.in <span><img src={`${process.env.PUBLIC_URL}/images/Footer-Icon-02.webp`} width='25' height='25' alt='MailId' /></span></a><br></br>
                <a className="d-inline-block" href="tel:#" style={{ marginTop: '2%' }}>+91 9142121210 <span><img src={`${process.env.PUBLIC_URL}/images/Footer-Icon-01.webp`} width='25' height='25' alt='PhoneNo' /></span></a>
                <ul>
                  <li style={{ listStyle: 'none' }}>Mon-Sat: <span className="d-inline-block offset-left-10 text-white" style={{ marginTop: '2%' }}>9:00 AM - 6:00 PM</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container wow fadeInUp" data-wow-delay=".4s">
          <div className="footer-classic-aside">
            <p className="rights"><span style={{ fontSize: '20px' }}>&copy;&nbsp;</span><span className="copyright-year"></span>Copyright 2024 Trice Technologies. All right reserved.</p>
            <ul className="social-links">
              <li><a href="https://www.linkedin.com/company/trice-technologies/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><span><img src={`${process.env.PUBLIC_URL}/images/Social-Media-linkdin.webp`} alt='Linkedin' width='100%' height='100%' /></span><span class="sr-only">LinkedIn</span></a></li>
              <li>
                <a class="" href="https://www.facebook.com/tricetechnologiesindia" target="_blank" rel="noopener noreferrer" aria-label="Facebook"><span><img src={`${process.env.PUBLIC_URL}/images/Social-Media-fb.webp`} alt='Facebook' width='100%' height='100%' /></span>
                  <span class="sr-only">Facebook</span>
                </a>
              </li>
              <li>
                <a class="" href="https://www.instagram.com/trice_technologies/" target="_blank" rel="noopener noreferrer" aria-label="Instagram"><span><img src={`${process.env.PUBLIC_URL}/images/Social-Media-insta.webp`} alt='Instagram' width='100%' height='100%' /></span>
                  <span class="sr-only">Instagram</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
