import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const cards = [
    { type: 'image', src: `${process.env.PUBLIC_URL}/images/services/nutrimonk.png` },
    { type: 'video', src: 'https://www.youtube.com/embed/dQw4w9WgXcQ' }, // Example YouTube video
    { type: 'image', src: `${process.env.PUBLIC_URL}/images/services/tulsi.png` },
    { type: 'video', src: 'https://www.youtube.com/embed/ysz5S6PUM-U' }, // Another YouTube video
  ];

const CardSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    centerMode: false, // Optional: Enable this if you want to center slides
  };

  return (
    <div className="container pb-5 mb-5" style={{ padding: '20px' }}>
      <Slider {...settings}>
        {cards.map((card, index) => (
          <div
            key={index}
            style={{
              padding: '0 30px',
              marginRight: '20px', // Increased space between slides
            }}
          >
            <div
              style={{
                border: '1px solid #ddd',
                borderRadius: '8px',
                overflow: 'hidden',
                textAlign: 'center',
                marginRight:'20px'
              }}
            >
              {card.type === 'image' ? (
                <img
                  src={card.src}
                  alt={`Card ${index + 1}`}
                  style={{ width: '100%', height: 'auto' }}
                />
              ) : (
                <iframe
                  width="100%"
                  height="250px"
                  src={card.src}
                  title={`Video ${index + 1}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              )}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

// Custom arrow components
const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#007BFF',
        borderRadius: '50%',
      }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#007BFF',
        borderRadius: '50%',
      }}
      onClick={onClick}
    />
  );
};

export default CardSlider;
