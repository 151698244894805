import Carousel from 'react-bootstrap/Carousel';

function UncontrolledExample() {
  const slides = [
    {
      src: `${process.env.PUBLIC_URL}/images/banner/web-application.webp`,
      alt: 'Creative web design preview',
      title: 'Web Designing',
      text: 'Transform your business with robust and user-friendly web applications. We design, develop, and deliver solutions tailored to meet your unique needs, ensuring seamless functionality and growth.',
      links: [
        { href: '/webdesigning-company-in-palakkad-kerala/', text: 'View Projects' },
        { href: '/Contact-Trice-Technologies-Get-in-Touch-Today', text: 'Contact Us' }
      ]
    },
    {
      src: `${process.env.PUBLIC_URL}/images/banner/digital-marketing.webp`,
      alt: 'Digital marketing preview',
      title: 'Digital Marketing',
      text: 'Explore our expert digital marketing services tailored to grow your business. From SEO to social media, we deliver results that matter. Let’s take your brand to the next level.',
      links: [
        { href: '/best-digital-marketing-strategist-in-palakkad', text: 'View Projects' },
        { href: '/Contact-Trice-Technologies-Get-in-Touch-Today', text: 'Contact Us' }
      ]
    },
    {
      src: `${process.env.PUBLIC_URL}/images/banner/seo.webp`,
      alt: 'SEO services preview',
      title: 'Search Engine Optimization',
      text: 'We specialize in providing top-notch SEO services to help your business rank higher, drive traffic, and achieve growth. Let us optimize your website for success and take your brand to the next level.',
      links: [
        { href: '/seo-company-in-palakkad-kerala', text: 'View Projects' },
        { href: '/Contact-Trice-Technologies-Get-in-Touch-Today', text: 'Contact Us' }
      ]
    },
    {
      src: `${process.env.PUBLIC_URL}/images/banner/video-photo.webp`,
      alt: 'Photography and videography',
      title: 'Photography and Videography',
      text: 'We specialize in professional product photography and videography to boost your brand. From e-commerce to Amazon photography, we deliver exceptional visuals that drive sales and attract customers.',
      links: [
        { href: '/Professional-Photography-and-Videography-Services', text: 'View Projects' },
        { href: '/Contact-Trice-Technologies-Get-in-Touch-Today', text: 'Contact Us' }
      ]
    },
    {
      src: `${process.env.PUBLIC_URL}/images/banner/content-creation.webp`,
      alt: 'Content Creation',
      title: 'Content Creation',
      text: 'We specialize in creating engaging, high-quality content that drives results. From blog posts to social media, we provide tailored content creation services to boost your brand’s presence and engagement.',
      links: [
        { href: '/Best-Content-Creation-Services-in-palakkad', text: 'View Projects' },
        { href: '/Contact-Trice-Technologies-Get-in-Touch-Today', text: 'Contact Us' }
      ]
    }
  ];

  return (
    <Carousel>
      {slides.map((slide, index) => (
        <Carousel.Item key={index}>
          <div className="container-fluid carousel-slide d-flex flex-md-row">
            <div className='row'>
            <div className="carousel-image col-md-6 d-flex justify-content-center">
              <img
                className="d-block"
                src={slide.src}
                alt={slide.alt}
              />
            </div>
            <div className="carousel-content col-md-6 text-left">
              <h2>{slide.title}</h2>
              <p>{slide.text}</p>
              <div className="mt-3">
                {slide.links.map((link, linkIndex) => (
                  <a
                    key={linkIndex}
                    href={link.href}
                    className="btn btn-primary mr-2 mt-3 button-circle"
                  >
                    {link.text}
                  </a>
                ))}
              </div>
            </div>
            </div>
            
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default UncontrolledExample;
