import React, { useState } from "react";


const Features = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqData = [
    { question: "Lightweight for Quick Loading", answer: "A fast-loading website is crucial for retaining visitors. Ensure your pages are optimized to be lightweight and responsive, offering seamless performance on both desktop and mobile devices." },
    { question: "Cross-Platform Support", answer: "Modern shoppers use multiple devices, from smartphones to tablets and desktops. A cross-platform compatible website ensures a consistent user experience across all platforms." },
    { question: "User-Friendly Navigation", answer: "Streamlined navigation helps users find what they need with minimal effort. Use intuitive menus and clear categories to guide visitors through your store." },
    { question: "Easy to Add to Cart and Checkout", answer: "Simplify the shopping process with user-friendly cart functionality and a straightforward checkout process. Reduce steps and eliminate unnecessary distractions to improve conversion rates." },
    { question: "Live Chat with Users", answer: "Integrating live chat allows real-time communication with customers, answering their queries instantly. This enhances user experience and builds trust." },
    { question: "Multiple Payment Options", answer: "Support diverse payment methods such as credit cards, digital wallets, and bank transfers to accommodate all customer preferences." },
    { question: "Real-Time Notifications", answer: "Keep users informed with real-time updates on order status, shipping, and exclusive offers via email, SMS, or push notifications." },
    { question: "Detailed Customer Tracking", answer: "Gain valuable insights into customer behavior with tracking features. Understand their preferences to offer personalized recommendations and improve sales." },
    { question: "Delivery & Return Tracking", answer: "Provide detailed tracking options for deliveries and returns. This transparency fosters trust and enhances the post-purchase experience." },
    { question: "Unlimited Products and Storage", answer: "Ensure your website can handle extensive product catalogs without compromising performance, offering unlimited storage for high-quality images and data." },
    { question: "Stock Management System", answer: "Efficient stock management helps you avoid overstocking or running out of inventory. Include low-quantity alerts to restock essential products on time." },
    { question: "Performance Reports", answer: "Monitor your website’s performance with detailed reports on sales, traffic, and customer engagement. " },
    { question: "Cloud-Secured Infrastructure", answer: "Protect your e-commerce website with cloud-secured hosting. Safeguard sensitive customer data against breaches and cyber threats." },
    { question: "Frequent Backup Facility", answer: "Regular backups ensure your data is safe and can be restored quickly in case of unforeseen issues, minimizing downtime." },
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-container feature-faq container py-5">
      <div className="row">
        {faqData.map((item, index) => (
          <div className="col-md-6 mb-4" key={index}>
            <div
              className={`faq-item border p-3 rounded ${openIndex === index ? "open" : ""}`}
              onClick={() => toggleFAQ(index)}
            >
              <div className="faq-question d-flex align-items-center">
                <span
                  className="faq-icon me-2"
                  style={{ color: "orange", fontWeight: "bold" }}
                >
                  {openIndex === index ? "-" : "+"}
                </span>
                <span className="question fw-bold">{item.question}</span>
              </div>
              {openIndex === index && (
                <div className="faq-answer mt-2 text-muted">{item.answer}</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
