import React from 'react';

import UncontrolledExample from './Carousel';
import ProjectsSection from './Tabs';
import { Link } from 'react-router-dom';
import AutoCounter from './AutoCounter';
import { Helmet } from 'react-helmet';




const Home=()=>{
 
    return(
        <div>
        <div>
      <Helmet>
        <title>Top Website development company in Palakkad,Kerala | Trice Technologies</title>
        <meta name='title' content='Top Website development company in Palakkad,Kerala | Trice Technologies'/>
        <meta name="description" content="Best web development, digital marketing, content, video & photography services in Palakkad. Grow your business online with our all-in-one solution!" />
        <meta name="keywords" content="web design Palakkad, website design in Kerala, Palakkad web development company, web designing Palakkad, website design company in Palakkad, web development company in Palakkad, domain registration Palakkad, web Hosting company Palakkad" />
        <link rel="canonical" href="https://tricetechnologies.in/" />
        
        <meta property="og:title" content="Top Website development company in Palakkad,Kerala | Trice Technologies" />
        <meta property="og:type" content="Website" />
        <meta property="og:url" content="https://tricetechnologies.in/" />
        <meta property="og:image" content="https://tricetechnologies.in/Logo-Dark.png" />
        <meta property="og:description" content="Trice Technologies: Web development, digital marketing, content, video & photography. Grow your business online - all-in-one solution!" />
     

       




      <script type='application/ld+json'> 
{
`{

    "@context": "http://www.schema.org",
  "@type": "product",
  "brand": "Trice technologies",
  "name": "Top Website development company in Palakkad,Kerala | Trice Technologies",
  "image": "https://tricetechnologies.in/images/banner/slider1.webp",
  "description": "Best web development, digital marketing, content, video & photography services in Palakkad. Grow your business online with our all-in-one solution!",
  "aggregateRating": {
    "@type": "aggregateRating",
    "ratingValue": "4.5",
    "reviewCount": "19"
  }
}

`
  
}
 </script>




</Helmet>
     
    </div>
            <div className="page">
      <div id="home">
       
        
        
<UncontrolledExample/>
        
      

       

        <section className="section section-sm position-relative mt-3 mb-3" id="about">
  <div className="container">
    <div className="row row-30">
      <div className="col-lg-6">
        <div className="block-decorate-img wow" data-wow-delay=".2s" data-aos='fade-right' style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInLeft' }}>
          <img src={`${process.env.PUBLIC_URL}/images/home/about-us.webp`} alt="web designing company in palakkad
" width="570" height="351" loading='lazy' />
        </div>
      </div>
      <div className="col-lg-6 col-12">
        <div className="block-sm offset-top-45">
         
          <h1 className="wow text-capitalize devider-bottom home-heading" data-aos='fade-left' data-wow-delay=".3s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInLeft' }}>Top<span className="text-primary"> Website Development Company </span> in Palakkad  </h1>
          <p className="offset-xl-40 wow" data-aos='fade-up' data-wow-delay=".4s" style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }}>Do you want to make your business an ONLINE SUPERSTAR overnight? Trice technologies is here to help you with the <Link to="/webdesigning-company-in-palakkad-kerala/" className='internal-link'>best website developers.</Link> </p>
          <p className="offset-xl-40 wow" data-aos='fade-up' data-wow-delay=".4s" style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }}>Trice Technologies works on the principles of affordability and reliability. We understand the essence of your business and pour it into our <Link to="/webdesigning-company-in-palakkad-kerala/" className='internal-link'>web design services.</Link>Our technical experts work round the clock with designers to breathe life into your online presence.</p>
          <p className="offset-xl-40 wow" data-aos='fade-up' data-wow-delay=".4s" style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }}>Each brand has a different story. Through our website design services, you can quickly TURN VISITORS INTO LEADS. Give us a call now and get your quote for FREE!</p>
         
         

          <a className="button-width-190 button-primary button-circle button-lg button offset-top-30 mt-3" href="/WebDesign-and-Digital-Marketing-Experts">read more</a>
        </div>
      </div>
    </div>
    <div className='row about-us mt-5'>
    <p className="default-letter-spacing font-weight-bold text-gray-dark wow " data-aos='fade-up' data-wow-delay=".4s" style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }}>BEST WEBSITE DESIGN COMPANY IN PALAKKAD</p>

<p className="offset-xl-40 wow text-justify" data-aos='fade-up' data-wow-delay=".4s" style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }}>Trice Technologies was born with the vision to offer the best website development services and unique brand identities.We offer a complete visual identity bundle and the best website design services to our clients. Besides the best website design, we also provide <Link to="/seo-company-in-palakkad-kerala" className='internal-link'>Search Engine Optimization (SEO)</Link>, Social Media Marketing (SEM),<Link to="/best-digital-marketing-strategist-in-palakkad/" className='internal-link'>Digital Marketing</Link> , Email and Web Hosting, Content Management Systems (CMS),<Link to="/webdesigning-company-in-palakkad-kerala/" className='internal-link'>Web Development</Link>, Shopping Cart designing and Ecommerce Website Development services.</p>

    </div>
  </div>
  <div className="decor-text decor-text-1" data-aos='fade-left'>ABOUT</div>
</section>


<section className="section custom-section position-relative section-md">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-7 col-12">
              <div className="section-name wow" data-aos='fade-right'>What We Offer</div>
              <h3 className="text-capitalize devider-left wow" data-aos='fade-left' data-wow-delay=".2s">Our <span className="text-primary"> Services</span></h3>
              <p style={{textAlign: 'center'}}>We, at Trice Technologies, are driven by design and customer satisfaction. We don’t only give brands a striking identity; we make them THE BOMB! So, be a BADASS in your business niche and discover the TRUE potential of your business by opting for the following services</p>
              <br></br>
              <div className="row row-15">

                <div className="col-xl-6 wow" data-aos='fade-up' data-wow-delay=".3s">
                  <a href='/webdesigning-company-in-palakkad-kerala/'>
                  <div className="box-default">
                    <div className="box-default-title" > <span><img src={`${process.env.PUBLIC_URL}/images/Icon-Website-Dev.webp`} width='100%' height='100%' alt='website icon' loading='lazy'/></span><span style={{marginLeft: '2%'}} >Web Development</span></div>
                    <p className="box-default-description">You can make your brand more accessible to clients with web and mobile applications. Besides being the best website design company, our services also include software and application development. So, amp up your business with our technical expertise.</p><span className="box-default-icon novi-icon icon-lg mercury-icon-target-2"></span>
                  </div>
                  </a>
                </div>


                <div className="col-xl-6 wow fadeInUp" data-wow-delay=".2s">
                    <a href="/best-digital-marketing-strategist-in-palakkad/">
                    <div className="box-default">
                      <div className="box-default-title"><span><img src={`${process.env.PUBLIC_URL}/images/Icon-Digital-marketing.webp`} width='100%' height='100%' alt='digital-marketing-icon' loading='lazy'/></span><span style={{marginLeft: '2%'}}>Digital Marketing</span></div>
                      <p className="box-default-description">You might be thinking SMM is the do all of online marketing. But the internet is just not limited to social media. Digital marketing includes advertising on all digital channels – from online banner advertising to email marketing. So, ignite your brand sales and let our experts work magic for your business.</p><span className="box-default-icon novi-icon icon-lg mercury-icon-lightbulb-gears"></span>
                    
                    </div>
                    </a>
                  </div>

                
                <div className="col-xl-6 wow" data-aos='fade-up' data-wow-delay=".4s">
                  <a href='/seo-company-in-palakkad-kerala '>

                  
                  <div className="box-default">
                    <div className="box-default-title seo-box" > <span ><img src={`${process.env.PUBLIC_URL}/images/Icon-SEO.webp`} width='100%' height='100%' alt='seo-icon' loading='lazy'/></span><span style={{marginLeft: '2%'}}>SEO</span></div>
                    <p className="box-default-description">The best way to bring organic traffic to your website is by ranking it on search engines. Search optimized ranking helps you get regular leads and business to your website. With our SEO services, you can bank on us to convert leads into clients.</p><span className="box-default-icon novi-icon icon-lg mercury-icon-user"></span>
                  </div>
                  </a>
                </div>
                
                <div className="col-xl-6 wow" data-aos='fade-up' data-wow-delay=".5s">
                  <a href='/'>
                  <div className="box-default">
                    <div className="box-default-title" ><span style={{paddingRight: '1%'}}><img src={`${process.env.PUBLIC_URL}/images/Icon-Content.webp`} width='100%' height='100%' alt='content-icon' loading='lazy'/></span><span style={{marginLeft: '1%'}}>Ecommerce </span></div>
                    <p className="box-default-description">Do you have big plans to open an online retail business? Well, you need a functional e-commerce website to start. Ecommerce website development requires a structured approach of keeping a tab of the supply, connecting with a shipping company, and growing the network. At Trice Technologies, our technophiles will transform your business into online retail giants with their developmental aptitude.</p><span className="box-default-icon novi-icon icon-lg mercury-icon-partners"></span>
                  </div>
                  </a>
                </div>
                <div className="col-xl-6 wow" data-aos='fade-up' data-wow-delay=".3s">
                  <a href='/Professional-Photography-and-Videography-Services'>
                  <div className="box-default b-1 pt-2">
                    <div className="box-default-title" > <span><img src={`${process.env.PUBLIC_URL}/images/Icon-Photography.webp`} width='100%' height='100%' alt='photography-icon' loading='lazy' /></span><span style={{marginLeft: '2%'}}>Photo & Videography</span></div>
                    <p className="box-default-description">We Ensure your products look their best for online listings and promotions</p><span className="box-default-icon novi-icon icon-lg mercury-icon-target-2"></span>
                  </div>
                  </a>
                </div>
                <div className="col-xl-6 wow " data-aos='fade-up' data-wow-delay=".5s">
                  <a href='/Best-Content-Creation-Services-in-palakkad'>
                  <div className="box-default b-2 pt-2">
                    <div className="box-default-title" ><span style={{paddingRight: '1%'}}><img src={`${process.env.PUBLIC_URL}/images/Icon-Content.webp`} width='100%' height='100%' alt='content-icon' loading='lazy'/></span><span style={{marginLeft: '1%'}}>Content Creation</span></div>
                    <p className="box-default-description">we provide customized content solutions that drive engagement and foster brand loyalty.</p><span className="box-default-icon novi-icon icon-lg mercury-icon-partners"></span>
                  </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="image-left-side wow" data-aos='slide-right'><img src="images/home/service.webp" alt="
    digital marketing palakkad,best digital marketing palakkad,digital marketing company palakkad" width="636" height="240" loading='lazy'/>
        </div>
        <div className="decor-text decor-text-2 wow" data-aos='fade-up'>Services</div>
      </section>


      <section className="section bg-image-2 pt-4 pb-4" loading="lazy">
        <div className="container">
          <div className="row row-30 text-center">
            <div className="contact-section">
              <h4>Join us to drive your Brand's Success - Contact Now</h4>
              <h2><span><img src={`${process.env.PUBLIC_URL}/images/Call-Icon.webp`} alt='Phone no' width="25" height="25" loading='lazy' /></span><span style={{marginLeft: '2%'}}>+91 9142 121210</span></h2>
              <a className="button-width-190 button-primary button-circle button-lg button offset-top-30 mt-3" href="/Contact-Trice-Technologies-Get-in-Touch-Today">Get Quote</a>
            </div>
            
          </div>
        </div>
      </section>

      <section className="section section-sm position-relative" id="about">
  <div className="container">
    <div className="row row-30">
      <div className="col-lg-6">
        <div className="block-decorate-img wow" data-wow-delay=".2s" data-aos='fade-right' style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInLeft' }}>
          <img src="./images/home/about-1.webp" alt="Best web development palakkad
webdesign palakkad
webdevolpment palakkad" width="570" height="351" loading='lazy' />
        </div>
      </div>
      <div className="col-lg-6 col-12">
        <div className="block-sm offset-top-45">
          <div className="section-name wow" data-wow-delay=".2s" data-aos='fade-left' style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInRight' }}>About us</div>
          <h1 className="wow text-capitalize devider-bottom1 home-heading" data-aos='fade-left' data-wow-delay=".3s" style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInLeft' }}>What makes <span className="text-primary">Trice Technologies </span>  STAND OUT?  </h1>
          <p className="offset-xl-40 wow" data-aos='fade-up' data-wow-delay=".4s" style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }}>We make eye-catching, user-friendly, and responsive designs, giving your clients equal access from mobiles, laptops, or desktops. Unlike other website development company, we encourage emotion and creativity in our designs. We guarantee to make your website the ULTIMATE one in its niche!</p>
          <p className="offset-xl-40 wow" data-aos='fade-up' data-wow-delay=".4s" style={{ visibility: 'visible', animationDelay: '0.4s', animationName: 'fadeInUp' }}>So, skyrocket your traffic, sales, and ROI with the <Link to="/webdesigning-company-in-palakkad-kerala/" className='internal-link'>best website design</Link>  and relatable multimedia content. And that too at absolute rock-bottom prices! So, hurry! Make your brand an internet superstar NOW!</p>
         


         

          
        </div>
      </div>
    </div>
  </div>
  
</section>
     

<ProjectsSection/>
<hr className="centered-line" />
    
<AutoCounter/>

     



      <section className="section section-sm bg-default news" id="news">
        <div className="container">
        <div className="section-name wow" data-aos='fade-right'>Blogs</div>
        <h3 className="text-capitalize devider-left wow" data-aos='fade-left' data-wow-delay=".2s">Fresh <span className="text-primary">Insights</span> and<span className="text-primary"> Updates!</span></h3>
         
          <div className="row row-45">
            <div className="col-sm-6 col-lg-4 wow" data-aos='fade-left'>
             
              <article className="post post-modern"><Link className="post-modern-figure" to="/digital-marketing-blog"><img src="./images/home/digital marketing-post.webp" alt="digital marketing palakkad,
,digital marketing company palakkad" width="370" height="307" loading='lazy'/>
                  <div className="post-modern-time">
                    <time dateTime="2019-07-04"><span className="post-modern-time-month">07</span><span className="post-modern-time-number">04</span></time>
                  </div></Link>
                <h4 className="post-modern-title"><a href="/best-digital-marketing-strategist-in-palakkad/">digital marketing isn't just about bombarding you with ads.</a></h4>
                <p className="post-modern-text">The digital age has fundamentally transformed how brands connect with consumers. Gone are the days of passive advertising...</p>
              </article>
            </div>
            <div className="col-sm-6 col-lg-4 wow" data-aos='fade-up' data-wow-delay=".1s">
              
              <article className="post post-modern"><Link className="post-modern-figure" to="/ecommerce-seo"><img src="./images/home/ecommerce-post.webp" alt="best seo palakkad,
seo ,
seo agency palakkad" width="370" height="307" loading='lazy'/>
                  <div className="post-modern-time">
                    <time dateTime="2019-07-17"><span className="post-modern-time-month">07</span><span className="post-modern-time-number">17</span></time>
                  </div></Link>
                <h4 className="post-modern-title"><a href="/ecommerce-seo">Boosting Sales with Effective E-commerce SEO Strategies</a></h4>
                <p className="post-modern-text">In the fiercely competitive world of e-commerce, standing out from the crowd and driving sales requires more than just having a great product...</p>
              </article>
            </div>
            <div className="col-sm-6 col-lg-4 wow" data-aos='fade-left' data-wow-delay=".2s">
             
              <article className="post post-modern"><Link className="post-modern-figure" to="/ecommerce-blog"><img src="./images/home/ecommerce-seo-post.webp" alt="best seo palakkad,
seo palakkad,
seo agency palakkad" width="370" height="307" loading='lazy'/>
                  <div className="post-modern-time">
                    <time dateTime="2019-07-22"><span className="post-modern-time-month">07</span><span className="post-modern-time-number">22</span></time>
                  </div></Link>
                <h4 className="post-modern-title"><a href="/ecommerce-blog">Crafting Successful E-commerce Sites</a></h4>
                <p className="post-modern-text">The e-commerce landscape is booming! Experts predict global retail e-commerce sales to hit a whopping $6.33 trillion in 2024...</p>
              </article>
            </div>
          </div>
        </div>
      </section>

      <section className="section section-md bg-gray-lighten review">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-12">
                    <div className="section-name wow text-center" data-aos='fade-right' data-wow-delay=".2s">testimonials</div>
                    <h3 className="wow text-capitalize text-center" data-aos='fade-left' data-wow-delay=".3s">What People Say<span className="text-primary"> About Us</span></h3>
                    <div className="review-carousel">
                     



                     
                     
                        <div className="slides">
                        <div className="item" id="item1">
                                <div className="item-preview"><img src="images/home/review4.webp" alt="client4" width="216" height="108" loading='lazy'/></div>
                                <div className="item-description">
                                    <p> Excellent service support and a dedicated team which is always ready to extend help at any time......</p>
                                    <div className="item-subsection"><span className="item-subsection-title devider-left">SICOMS Kerala</span></div>
                                </div>
                            </div>
                            <div className="item" id="item2">
                                <div className="item-preview"><img src="images/home/review2.webp" alt="client2" width="216" height="108" loading='lazy'/></div>
                                <div className="item-description">
                                    <p>They gave us dedicated support in our website designing process and helped us in fixing the changes. Thank you and keep up the good work.</p>
                                    <div className="item-subsection"><span className="item-subsection-title devider-left">Info Orange</span></div>
                                </div>
                            </div>
                            <div className="item" id="item3">
                                <div className="item-preview"><img src="images/home/review3.webp" alt="client3" width="216" height="108" loading='lazy'/></div>
                                <div className="item-description">
                                    <p>Mr Sivadas is such a pleasant person to deal with. They will be available for you throughout the process. Good work.</p>
                                    <div className="item-subsection"><span className="item-subsection-title devider-left">CanFord</span></div>
                                </div>
                            </div>
                            <div className="item" id="item4">
                                <div className="item-preview"><img src="images/home/review4.webp" alt="client4" width="216" height="108" loading='lazy'/></div>
                                <div className="item-description">
                                    <p> Excellent service support and a dedicated team which is always ready to extend help at any time......</p>
                                    <div className="item-subsection"><span className="item-subsection-title devider-left">SICOMS Kerala</span></div>
                                </div>
                            </div>
                        </div>


                        <div class="centered">
  <a href='https://www.google.com/search?gs_ssp=eJzj4tVP1zc0TCqJz8rKzjAzYLRSNagwTkq0MEs1ME4xMjCwNLY0tDKoMLEwSko1SDMwN7VIMjY3NPUSKinKTE5VKElNzsjLz8lPz0wtBgAJCBX7&q=trice+technologies&rlz=1C1GCEW_enIN1107IN1107&oq=trice+tech&gs_lcrp=EgZjaHJvbWUqEggBEC4YJxivARjHARiABBiKBTIPCAAQIxgnGOMCGIAEGIoFMhIIARAuGCcYrwEYxwEYgAQYigUyBggCEEUYOTIHCAMQABiABDIICAQQABgWGB4yCAgFEAAYFhgeMgYIBhBFGDwyBggHEEUYPNIBCDQzNjFqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8#lrd=0x3ba86e03d2009391:0x482be0f0758b3715,1,,,,'>More Google Reviews</a>
</div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>


      </div>
     


     
      

     

     
     
      
    

     


    
    

    

     
      
      

      
      
    

     
    </div>
        </div>
    )
}

export default Home;